<template>
  <div>
    <v-icon color="orange"
            small
            class="mr-2"
            @click="_openConfirmDialog(item)"
    >mdi-trash-can
    </v-icon>
      <v-dialog v-model="confirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          តើអ្នកនឹងលុប របាយការណ៏នេះមែនទេ ?
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialog"
            >ថយ</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            color="error"
            @click="_remove"
            >លុប</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ['darkmode', "item", "params"],
  data() {
    return {
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null,

            
      },
      selectedData:[],
              confirmDialog: false,
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    async updateData() {
      await this.fetchCurrentVipPercentageData(this.item);
      this.fetchPopUp(true);
    },
       _openConfirmDialog(item){

      this.selectedData = item;
      this.confirmDialog = true;
    },
    _closeDialog(){
      this.selectedData = []
      this.confirmDialog = false;
    },
    _remove(){
      let param = {
        channel_type : this.selectedData.channel_type,
        bet_id : this.selectedData._id,
        user_id : this.selectedData.user_id
      }
      console.log("param", param)
       this.$request
        .post({
          url: "coChannel/cancelBetting",
          data: param,
        })
        .then(() => {
           this.$emit("refreshPage");
            this._closeDialog();
        }
        
         
        );
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_vipPercentage", ["fetchPopUp", "fetchCurrentVipPercentageData", "deleteData", "fetchGetAllPayOut",]),
  },
};
</script>
